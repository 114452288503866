import { createSlice } from '@reduxjs/toolkit';
import { getCabinet, getCabinetById } from './cabinetThunk';
const initialState = {
    data: [],
    oneData: undefined,
    loading: false,
    error: null,
    total: 0,
};
const slice = createSlice({
    name: 'cabinet',
    initialState,
    reducers: {
        clearOneData: (state) => {
            state.oneData = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCabinet.pending, (state, actions) => {
            state.loading = true;
        })
            .addCase(getCabinet.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.docs;
            state.total = payload.meta.totalDocs;
        })
            .addCase(getCabinet.rejected, (state, { payload }) => {
            state.error = true;
            state.loading = false;
        })
            .addCase(getCabinetById.pending, (state, actions) => {
            state.loading = true;
        })
            .addCase(getCabinetById.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.oneData = payload?.data?.data;
        })
            .addCase(getCabinetById.rejected, (state, { payload }) => {
            state.error = true;
            state.loading = false;
        });
    },
});
// Reducer
export default slice.reducer;
// Actions
export const { clearOneData } = slice.actions;
